<template>
  <div>
    <!-- <el-dialog
      v-dialogDrag
      title="公司认证信息"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="1300px"
      :before-close="handleClose"
      v-loading="loading"
    > -->
    <div class="personalCenterContainer" :class="{ newTitle: enterpriseLink }">
      <div class="tip">
      温馨提示：上传图片为jpeg，png或jpg格式，且上传图片大小不能超过20MB！
    </div>
      <div class="goIdentification">
        <el-form ref="form" :model="form" :rules="rules" label-width="140px">
          <el-row>
            <el-col :span="24">
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="法人姓名" prop="legalPersonName">
                    <el-input
                      v-model="form.legalPersonName"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="法人证件类型" prop="personCertType">
                    <el-select
                      disabled
                      v-model="personCertType"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in typeOptions"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="证件号码" prop="legalPersonCertCode">
                    <el-input
                      v-model="form.legalPersonCertCode"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="公司名称" prop="name">
                    <el-input
                      v-model="form.name"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="公司证件类型" prop="orgCertType">
                    <el-select
                      disabled
                      v-model="orgCertType"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in companyOptions"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="公司证件代码" prop="certNo">
                    <el-input
                      v-model="form.certNo"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="紧急联系人姓名" prop="urgencyContact">
                    <el-input
                      v-model="form.urgencyContact"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系人手机号" prop="urgencyContactPhone">
                    <el-input
                      v-model="form.urgencyContactPhone"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="20" v-if="isTwo">
                   <el-col :span="11">
                    <el-form-item label="法人手机号" prop="cellphone">
                      <el-input
                        v-model="form.cellphone"
                        :disabled="isAlreadyDisabled"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                 </el-row> -->
              <el-row :gutter="24">
                <el-col :span="24">
                  <el-form-item label="联系地址" prop="urgencyContactAddress">
                    <el-input
                      v-model="form.urgencyContactAddress"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="10">
                  <el-form-item label="营业执照" prop="orgCertImg">
                    <el-upload
                      class="avatar-uploader"
                      :action="actionUrl"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUploadFile"
                      :disabled="isAlreadyDisabled"
                      :data="postData"
                    >
                      <img
                        v-if="form.orgCertImg"
                        :src="form.orgCertImg"
                        class="avatar"
                      />
                      <img v-else
                        src="../../../assets/images/guide/yyzz.png"
                        class="avatar"
                      />
                      <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="9">
                  <el-form-item label="法人身份证国徽面" prop="cartNegativeImg">
                    <!--  :disabled="isAlreadyDisabledRD" -->
                    <el-upload
                      class="avatar-uploader"
                      :action="actionUrl"
                      :show-file-list="false"
                      :on-success="handlePositiveCardSuccess"
                      :before-upload="beforePositiveCardUpload"
                      :disabled="isAlreadyDisabled"
                      :data="postData"
                    >
                      <img
                        v-if="form.cartNegativeImg"
                        :src="form.cartNegativeImg"
                        class="avatar"
                      />
                      <img v-else
                        src="../../../assets/images/guide/ghm.png"
                        class="avatar"
                      />
                      <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="法人身份证头像面" prop="cartFrontImg">
                    <el-upload
                      class="avatar-uploader"
                      :action="actionUrl"
                      :show-file-list="false"
                      :on-success="handleReverseCardSuccess"
                      :before-upload="beforeReverseCardUpload"
                      :disabled="isAlreadyDisabled"
                      :data="postData"
                    >
                      <img
                        v-if="form.cartFrontImg"
                        :src="form.cartFrontImg"
                        class="avatar"
                      />
                      <img v-else
                        src="../../../assets/images/guide/txm.png"
                        class="avatar"
                      />
                      <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="20" v-if="!isAlreadyDisabled">
                <el-col :span="24">
                  <el-form-item>
                    <p class="tip" style="'dont-size: 18px">
                      温馨提示：上传图片为jpeg，png或jpg格式，且上传图片大小不能超过20MB！
                    </p>
                  </el-form-item>
                </el-col>
              </el-row> -->
            </el-col>
          </el-row>
          <div class="submitIdentification" v-if="!isAlreadyDisabled">
            <el-button type="primary" @click="submitIdentification()">{{
              isStep ? "下一步" : "提交"
            }}</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <!-- </el-dialog> -->
  </div>
</template>
<script>
import md5 from "js-md5";

export default {
  props: {
    dataTimeout: Object,
    datas: Object,
    BDAuthentication: Object,
    isTwo: Boolean,
    isStep: Boolean
  },
  data() {
    return {
      personCertType: 1,
      orgCertType: 1,
      companyOptions: [
        {
          label: "社会统一代码",
          value: 1
        }
      ],
      typeOptions: [
        {
          label: "身份证",
          value: 1
        }
      ],
      isAlreadyDisabledRD: true,
      dialogVisible: false,
      form: {
        // cellphone: "", // 法人手机号
        legalPersonName: "", // 法人名称
        name: "", // 公司名称
        cartFrontImg: "", // 身份证反面
        cartNegativeImg: "", // 身份证正面
        urgencyContactAddress: "", // 联系地址
        legalPersonCertCode: "", // 身份证证件号码
        personCertType: 1, // 法人证件类型
        urgencyContactPhone: "", // 联系方式
        urgencyContact: "", // 紧急联系人姓名
        certNo: "", // 证件代码
        orgCertImg: "", // 证件照片(营业执照)
        orgCertType: 1 // 公司证件类型 1:营业执照号 2:组织机构代码 3:社会统一代码
        // id: null,
        // auditType: "O"
      },
      rules: {
        cellphone: [
          { required: true, message: "请输入法人手机号", trigger: "change" }
        ],
        legalPersonName: [
          { required: true, message: "请输入法人名称", trigger: "change" }
        ],
        personCertType: [
          { required: true, message: "请选择法人证件类型", trigger: "change" }
        ],
        legalPersonCertCode: [
          { required: true, message: "请输入证件号码", trigger: "change" }
        ],
        name: [
          { required: true, message: "请输入公司名称", trigger: "change" }
        ],
        orgCertType: [
          { required: true, message: "请选择公司证件类型", trigger: "change" }
        ],
        certNo: [
          { required: true, message: "请选择公司证件代码", trigger: "change" }
        ],
        urgencyContact: [
          { required: true, message: "请输入紧急联系人姓名", trigger: "change" }
        ],
        urgencyContactPhone: [
          { required: true, message: "请输入联系人手机号", trigger: "change" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ],
        orgCertImg: [
          { required: true, message: "请上传营业执照", trigger: "change" }
        ],
        cartNegativeImg: [
          {
            required: true,
            message: "请上传法人身份证正面",
            trigger: "change"
          }
        ],
        cartFrontImg: [
          {
            required: true,
            message: "请上传法人身份证反面",
            trigger: "change"
          }
        ]
      },
      isAlreadyDisabled: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      enterpriseLink: null,
      contractOfficeStatus: null,
      loading: false,
      localFile: null //营业执照文件数据
    };
  },
  components: {},
  created() {
    const that = this;

    setTimeout(() => {
      console.log("公司认证", that.datas);
      if (that.datas != {}) {
        if (that.datas.auditType == "O") {
          if (that.datas.auditStatus != 2) {
            that.form = that.datas;
            that.form.personCertType = 1;
            that.form.orgCertType = 1;
            that.isAlreadyDisabled = true;
            that.isAlreadyDisabledRD = true;
          } else {
            that.form = that.datas;
            that.isAlreadyDisabled = false;
            that.isAlreadyDisabledRD = false;
            that.form.personCertType = 1;
            that.form.orgCertType = 1;
            // that.form.personCertType = 1
          }
        }
      } else {
      }
    }, 1000);
    console.log(this.isAlreadyDisabled, "90909");
  },
  watch: {
    dataTimeout(val) {
      if (val != {}) {
        if (this.dataTimeout.auditType == "O") {
          if (this.dataTimeout.auditStatus != 2) {
            this.form = val;
            this.isAlreadyDisabled = true;
            this.isAlreadyDisabledRD = true;
          } else {
            this.form = val;
            this.isAlreadyDisabled = false;
            this.isAlreadyDisabledRD = false;
          }
          console.log(val, "公司认证");
        }
      }
    },
    // datas(val) {
    //   if (val != {}) {
    //     if (this.datas.auditType == "O") {
    //       if (this.datas.auditStatus != 2) {
    //         this.form = val;
    //         this.isAlreadyDisabled = true;
    //         this.isAlreadyDisabledRD = true;
    //       } else {
    //         this.form = val;
    //         this.isAlreadyDisabled = false;
    //         this.isAlreadyDisabledRD = false;
    //       }
    //       console.log(val, "公司认证");
    //     }
    //   }
    // },
    BDAuthentication: {
      handler() {
        // this.isAlreadyDisabledRD = true;
        // this.form.legalPersonName = this.BDAuthentication.name;
        // this.form.legalPersonCertCode = this.BDAuthentication.idCardNumber;
        // // 反面
        // this.form.cartFrontImg = this.BDAuthentication.frontBase64;
        // // 正面
        // this.form.cartNegativeImg = this.BDAuthentication.backBase64;
      },
      immediate: true
    }
  },
  methods: {
    showDialogs() {
      this.dialogVisible = true;
    },
    // 成功时回调(营业执照)
    handleAvatarSuccess(res, file) {
      this.form.orgCertImg = res.value;
      console.log(this.form.orgCertImg);
    },
    // 营业执照图片格式处理
    beforeAvatarUploadFile(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 20MB!");
      }
      // 获取图片原始数据
      this.localFile = file;
      return testmsg && isLt2M;
    },
    // 身份证正面(成功时回调)
    handlePositiveCardSuccess(res, file) {
      console.log(res, "res");
      this.form.personCartFrontImg = res.value;
      this.form.cartNegativeImg = res.value;
    },
    // 身份证正面(上传前回调)
    beforePositiveCardUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 20MB!");
      }
      return testmsg && isLt2M;
    },
    // 身份证反面(成功时回调)
    handleReverseCardSuccess(res, file) {
      this.form.personCartNegativeImg = res.value;
      this.form.cartFrontImg = res.value;
    },
    // 身份证反面(上传前回调)
    beforeReverseCardUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 20MB!");
      }
      return testmsg && isLt2M;
    },
    submitIdentificationTest() {
      console.log("公司", this.form);
    },
    // 提交
    submitIdentification() {
      if (!this.form.orgCertImg) {
        this.$EL_MESSAGE('请上传营业执照')
        return
      }
      if (!this.form.cartNegativeImg) {
        this.$EL_MESSAGE('请上传法人身份证正面')
        return
      }
      if (!this.form.cartFrontImg) {
        this.$EL_MESSAGE('请上传法人身份证反面')
        return
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.legalPersonCertCode = this.form.legalPersonCertCode.replace(
            /\s*/g,
            ""
          );
          // if (this.BDAuthentication.name != this.form.legalPersonName) {
          //   this.$EL_MESSAGE("所填姓名与人脸识别录入信息不匹配");
          //   return;
          // }
          // if (
          //   this.BDAuthentication.idCardNumber != this.form.legalPersonCertCode
          // ) {
          //   this.$EL_MESSAGE("所填身份证号与人脸识别录入信息不匹配");
          //   return;
          // }
          this.loading = true;
          let _this = this;
          // FormData 对象
          protocolFwd.param_saveBaseUserAudit_company.param = this.form;
          this.form.legalPersonCertCode = this.form.legalPersonCertCode.replace(
            /\s*/g,
            ""
          );
          if (this.isTwo) {
            protocolFwd.param_saveBaseUserAudit_company.param.cellphone = this.form.cellphone;
            protocolFwd.param_saveBaseUserAudit_company.param.password = md5(
              "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" + 123456
            );
            protocolFwd.param_saveBaseUserAudit_company.param.auditsign = true;
            protocolFwd.param_saveBaseUserAudit_company.param.createWay = 2;
          } else {
            protocolFwd.param_saveBaseUserAudit_company.param.cellphone = null;
            protocolFwd.param_saveBaseUserAudit_company.param.password = null;
            protocolFwd.param_saveBaseUserAudit_company.param.auditsign = null;
            protocolFwd.param_saveBaseUserAudit_company.param.createWay = null;
          }
          http
            .postFront(protocolFwd.param_saveBaseUserAudit_company)
            .then(response => {
              const { code, message, value } = response.data;
              if (Number(code) === 0) {
                this.loading = false;
                localStorage.setItem("enterpriseLink", value);
                this.$EL_MESSAGE(message);
                this.handleClose();
                this.$emit("reLoad");
                this.dialogVisible = true;
              } else {
                this.loading = false;
                this.$EL_MESSAGE(message);
              }
            })
            .catch(res => {
              this.$EL_MESSAGE(res);
            });
        }
      });
    },
    handleClose() {
      if (this.datas == {}) {
        this.form = {
          personName: "", // 法人名称
          orgName: "", // 公司名称
          personCartNegativeImg: "", // 身份证反面
          personCartFrontImg: "", // 身份证正面
          urgencyContactAddress: "", // 联系地址
          personCertNo: "", // 身份证证件号码
          personCertType: 1, // 法人证件类型
          urgencyContactPhone: "", // 联系方式
          urgencyContact: "", // 紧急联系人姓名
          orgCertCode: "", // 证件代码
          orgCertImg: "", // 证件照片(营业执照)
          orgCertType: 1, // 公司证件类型 1:营业执照号 2:组织机构代码 3:社会统一代码
          id: null
        };
      }
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.personalCenterContainer {
  position: relative;
  // width: 80vw;
  margin: 0 auto;
  line-height: 32px;
  border-radius: 8px;
  .tip{
    width: calc(100% - 40px);
    position: absolute;
    top: 0;
    height: 56px;
    line-height: 56px;
    padding-left: 40px;
    background: #FFFAED;
    border-radius: 8px 8px 0px 0px;
    font-weight: bold;
    font-size: 16px;
    color: #E9AE2F;
  }
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.enterTitle {
  position: absolute;
  top: 45px;
  a {
    cursor: pointer;
  }
}
.newTitle {
  margin-top: 20px;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.noIdentification {
  margin: 100px 0;
  text-align: center;
}
.noIdentificationText {
  font-size: 18px;
  color: #9999a2;
}
.hasIdentification {
  margin: 20px 20px 0 20px;
}
.goIdentification {
  padding: 70px 80px 30px 80px;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 320px;
  height: 200px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
.tip {
  // font-size: 18px !important;
  color: red;
  line-height: 30px;
  font-weight: 700;
}
/* 头像 结束 */
/deep/ .el-button--primary{
    background: linear-gradient(0deg, #4C87F0 1%, #2D69D3 100%);
    border-radius: 8px;
  }
</style>
