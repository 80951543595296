<template>
  <div>
    <!-- <el-dialog
      v-dialogDrag
      title="个人认证信息"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="1300px"
      :before-close="handleClose"
      v-loading="loading"
    > -->
    <div class="personalCenterContainer" :class="{ newTitle: enterpriseLink }">
      <div class="goIdentification">
        <el-form ref="form" :model="form" :rules="rules" label-width="140px">
          <el-row>
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                  <el-form-item label="证件类型" prop="personCertType">
                    <el-select
                      :disabled="isAlreadyDisabled"
                      v-model="form.personCertType"
                      placeholder="请选择"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in typeOptions"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="证件号码" prop="certNo">
                    <el-input v-model="form.certNo" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                  <el-form-item label="紧急联系人姓名" prop="urgencyContact">
                    <el-input
                      v-model="form.urgencyContact"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-form-item label="联系人手机号" prop="urgencyContactPhone">
                    <el-input
                      v-model="form.urgencyContactPhone"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2"> </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="联系地址" prop="urgencyContactAddress">
                    <el-input
                      v-model="form.urgencyContactAddress"
                      :disabled="isAlreadyDisabled"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="身份证国徽面" prop="cartNegativeImg">
                    <el-upload
                      class="avatar-uploader"
                      :action="actionUrl"
                      :show-file-list="false"
                      :on-success="handlePositiveCardSuccess"
                      :before-upload="beforePositiveCardUpload"
                      :disabled="isAlreadyDisabledRD"
                      :data="postData"
                    >
                      <img
                        v-if="form.cartNegativeImg"
                        :src="form.cartNegativeImg"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="身份证头像面" prop="cartFrontImg">
                    <el-upload
                      class="avatar-uploader"
                      :action="actionUrl"
                      :show-file-list="false"
                      :on-success="handleReverseCardSuccess"
                      :before-upload="beforeReverseCardUpload"
                      :disabled="isAlreadyDisabledRD"
                      :data="postData"
                    >
                      <img
                        v-if="form.cartFrontImg"
                        :src="form.cartFrontImg"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="产业证明" prop="cartFrontImg">
                    <el-upload
                      class="avatar-uploader"
                      :action="actionUrl"
                      :show-file-list="false"
                      :on-success="handleIndustryProveImgSuccess"
                      :before-upload="beforeIndustryProveImgUpload"
                      :data="postData"
                    >
                      <!-- :disabled="isAlreadyDisabledRD" -->
                      <img
                        v-if="form.industryProveImg"
                        :src="form.industryProveImg"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" v-if="!isAlreadyDisabled">
                <el-col :span="24">
                  <el-form-item>
                    <p class="tip">
                      温馨提示：上传图片为jpeg，png或jpg格式，且上传图片大小不能超过20MB！
                    </p>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <div class="submitIdentification" v-if="!isAlreadyDisabled">
            <el-button type="primary" @click="submitIdentification()">{{
              isStep ? "下一步" : "提交"
            }}</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <!-- </el-dialog> -->
  </div>
</template>
<script>
export default {
  props: {
    dataTimeout: Object,
    datas: Object,
    BDAuthentication: Object,
    isStep: Boolean
  },
  created() {
    if (this.datas != {}) {
      if (this.datas.auditType == "P") {
        if (this.datas.auditStatus != 2) {
          this.form = this.datas;
          this.isAlreadyDisabled = true;
          this.isAlreadyDisabledRD = true;
        } else {
          this.form = this.datas;
          this.isAlreadyDisabled = false;
          this.isAlreadyDisabledRD = false;
        }
      }
    }
  },
  watch: {
    dataTimeout(val) {
      console.log(val, "dataTimeout");
      if (val != {}) {
        if (this.dataTimeout.auditType == "P") {
          if (this.dataTimeout.auditStatus != 2) {
            this.form = val;
            this.isAlreadyDisabled = true;
            this.isAlreadyDisabledRD = true;
            this.form.personCertType = 1;
          } else {
            this.form = val;
            this.isAlreadyDisabled = false;
            this.isAlreadyDisabledRD = false;
            this.form.personCertType = 1;
          }
          this.form.personCertType = 1;
        } else {
          this.form.personCertType = 1;
        }
      }
    },
    // datas(val) {
    //   console.log(val, "watch");
    //   if (val != {}) {
    //     if (this.datas.auditType == "P") {
    //       if (this.datas.auditStatus != 2) {
    //         this.form = val;
    //         this.isAlreadyDisabled = true;
    //         this.isAlreadyDisabledRD = true;
    //         this.form.personCertType = 1;
    //       } else {
    //         this.form = val;
    //         this.isAlreadyDisabled = false;
    //         this.isAlreadyDisabledRD = false;
    //         this.form.personCertType = 1;
    //       }
    //       this.form.personCertType = 1;
    //     } else {
    //       this.form.personCertType = 1;
    //     }
    //   }
    // },
    BDAuthentication: {
      handler() {
        this.isAlreadyDisabledRD = true;
        this.form.name = this.BDAuthentication.name;
        this.form.certNo = this.BDAuthentication.idCardNumber;
        // 反面
        this.form.cartFrontImg = this.BDAuthentication.frontBase64;
        // 正面
        this.form.cartNegativeImg = this.BDAuthentication.backBase64;
      },
      immediate: true
    }
  },
  data() {
    return {
      isAlreadyDisabledRD: true,
      companyOptions: [
        {
          label: "社会统一代码",
          value: 1
        }
      ],
      typeOptions: [
        {
          label: "身份证",
          value: 1
        }
      ],
      dialogVisible: false,
      form: {
        name: "",
        cartFrontImg: "", // 身份证反面
        cartNegativeImg: "", // 身份证正面
        urgencyContactAddress: "", // 联系地址
        certNo: "", // 身份证证件号码
        personCertType: 1, // 证件类型1：身份证
        urgencyContact: "", // 紧急联系人姓名
        urgencyContactPhone: "", // 联系方式
        industryProveImg: "" // 产业证明
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "change" }],
        personCertType: [
          { required: true, message: "请选择证件类型", trigger: "change" }
        ],
        certNo: [
          { required: true, message: "请输入证件号码", trigger: "change" }
        ],
        urgencyContact: [
          { required: true, message: "请输入紧急联系人姓名", trigger: "change" }
        ],
        urgencyContactPhone: [
          { required: true, message: "请输入联系人手机号", trigger: "change" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ],
        cartNegativeImg: [
          {
            required: true,
            message: "请上传身份证正面",
            trigger: "change"
          }
        ],
        cartFrontImg: [
          {
            required: true,
            message: "请上传身份证反面",
            trigger: "change"
          }
        ],
        industryProveImg: [
          {
            required: true,
            message: "请上传产业证明",
            trigger: "change"
          }
        ]
      },
      isAlreadyDisabled: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      enterpriseLink: null,
      loading: false
    };
  },
  components: {},
  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
    // 身份证正面(成功时回调)
    handlePositiveCardSuccess(res, file) {
      this.form.cartNegativeImg = res.value;
    },
    // 身份证正面(上传前回调)
    beforePositiveCardUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 20MB!");
      }
      return testmsg && isLt2M;
    },
    // 身份证反面(成功时回调)
    handleReverseCardSuccess(res, file) {
      this.form.cartFrontImg = res.value;
    },
    // 身份证反面(上传前回调)
    beforeReverseCardUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 20MB!");
      }
      return testmsg && isLt2M;
    },
    // 产业证明(成功时回调)
    handleIndustryProveImgSuccess(res, file) {
      this.form.industryProveImg = res.value;
    },
    // 产业证明(上传前回调)
    beforeIndustryProveImgUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 20MB!");
      }
      return testmsg && isLt2M;
    },
    submitIdentificationTest() {
      console.log("个人", this.form);
    },
    // 提交
    submitIdentification() {
      if (!this.form.cartNegativeImg) {
        this.$EL_MESSAGE('请上传身份证正面')
      }
      if (!this.form.cartFrontImg) {
        this.$EL_MESSAGE('请上传身份证反面')
      }
      if (!this.form.industryProveImg) {
        this.$EL_MESSAGE('请上传产业证明')
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.name = this.form.name.replace(/\s*/g, "");
          if (this.BDAuthentication.name != this.form.name) {
            this.$EL_MESSAGE("所填姓名与人脸识别录入信息不匹配");
            return;
          }
          if (this.BDAuthentication.idCardNumber != this.form.certNo) {
            this.$EL_MESSAGE("所填身份证号与人脸识别录入信息不匹配");
            return;
          }
          this.loading = true;
          let _this = this;
          protocolFwd.param_saveBaseUserAudit_user.param = this.form;
          this.form.certNo = this.form.certNo.replace(/\s*/g, "");
          http
            .postFront(protocolFwd.param_saveBaseUserAudit_user)
            .then(response => {
              this.loading = false
              const { code, message, value } = response.data;
              if (Number(code) === 0) {
                localStorage.setItem("enterpriseLink", value);
                this.$EL_MESSAGE(message);
                // this.handleClose();
                this.dialogVisible = true;
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(res => {
              this.loading = false
              this.$EL_MESSAGE(res);
            });
        }
      });
    },
    handleClose() {
      if (this.datas == {}) {
        this.form = {
          name: "",
          cartFrontImg: "", // 身份证反面
          cartNegativeImg: "", // 身份证正面
          urgencyContactAddress: "", // 联系地址
          certNo: "", // 身份证证件号码
          personCertType: 1, // 证件类型1：身份证
          urgencyContact: "", // 紧急联系人姓名
          urgencyContactPhone: "", // 联系方式
          industryProveImg: "" // 产业证明
        };
        this.dialogVisible = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.personalCenterContainer {
  // width: 80vw;
  margin: 0 auto;
  line-height: 32px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.enterTitle {
  position: absolute;
  top: 45px;
  a {
    cursor: pointer;
  }
}
.newTitle {
  margin-top: 20px;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.noIdentification {
  margin: 100px 0;
  text-align: center;
}
.noIdentificationText {
  font-size: 18px;
  color: #9999a2;
}
.hasIdentification {
  margin: 20px 20px 0 20px;
}
.goIdentification {
  padding: 30px 20px 0 20px;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 120px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
.tip {
  color: red;
  line-height: 30px;
  font-weight: 700;
  font-size: 18px;
}
/* 头像 结束 */
</style>
